<template>
	<div v-if="localData && localData.length" class="buttons" :class="styleClass">
        <div class="button-group" v-for="(button, index) in localData">
            <div v-if="button.title || button.text" class="additional-info">
                <h5 v-if="button.title">{{button.title}}</h5>
                <span v-if="button.text" v-html="button.text"></span>
            </div>
            <div v-if="button.url || button.action" class="button">
                <CSButton v-if="button.url" :data="button.url" :title="button.buttonText"/>
                <a v-if="button.action" @click="action(index)">{{button.buttonText}}</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CSUtilsButtons",
    components:{
        CSButton: () => import("./CSButton.vue"),
    },
    props: {
        data: {
            type:[Array,Object],
            default:()=>([])
        },
        cs_style: {
			type: String,
			default: 'left'
		},
        payload: String,
		alignment: {
			type: String,
			default: 'left'
		}
    },
	data() {
		return {
			localData: []
		}
	},
    methods:{
        action(i){
            if(!this.payload)this.localData[i].action();
            else this.localData[i].action(this.payload)
        }
    },
    computed: {
        styleClass(){
            let result = `buttons-align-${this.alignment}`

			if (this.cs_style) result += ` cs-style-${this.cs_style}`

			if (this.localData && this.localData.length != 0) {
				result = `buttons-align-${this.alignment} cs-style-${this.cs_style} cs-simple-button`
				for (var i = 0; i < this.localData.length; i++) {
					if (this.localData[i].title || this.localData[i].text) result = `buttons-align-${this.alignment} cs-style-${this.cs_style} cs-advanced-button`
				}
			}

			return result
        }
    },
    mounted() {
		if (Array.isArray(this.data)) this.localData = this.data;
		if (!Array.isArray(this.data) && typeof this.data == 'object') this.localData = Object.values(this.data);
		// console.log('CSUtilsButtons: Got data', this.data);
		// console.log('CSUtilsButtons: Set it locally as', this.localData);
	},
};
</script>

<style lang="scss" scoped>
.buttons {
	&.cs-style-color{
		a,
		::v-deep .cs-button {
			color: white;
			padding: 10px 15px;
			border-radius: 30rem;
			text-decoration: none;
			text-align:center;

			background: rgb(0,169,131);
			background: linear-gradient(70deg, rgba(0,169,131,1) 0%, rgba(43,215,132,1) 100%);

			&:hover {
			    background: rgb(7,199,211);
			    background: linear-gradient(70deg, rgba(7,199,211,1) 0%, rgba(0,255,255,1) 100%);
			}
		}
	}

	&.cs-simple-button {
		display:inline-block;
		.button-group{
	        display: inline-block;
	        padding-right: 15px;

			&:last-child {
				padding-right: 0px;
			}
	    }
    }
    &.cs-advanced-button .button-group{
        display: flex;
        align-items: center;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0px;
		}

        .button {
            max-width: 150px;
			margin-top: unset;

             a,
			 ::v-deep .cs-button {
				display: block;
                width:calc(100% - 10px);
            }
        }

        .additional-info {
            span {
                font-weight: 300;
                letter-spacing: 0.3px;
                font-size: 14px;
            }

            h5 {
                font-size: 15px;
                margin-bottom: 5px;
            }
        }
    }

	&.cs-advanced-button.buttons-align-left .button-group {
		flex-direction: row-reverse;
		justify-content: flex-end;

		.additional-info {
			margin-left: 1rem;
		}
	}

	&.cs-advanced-button.buttons-align-right .button-group {
		justify-content: flex-end;

		.additional-info {
			margin-right: 1rem;
		}
	}

	&.cs-advanced-button.buttons-align-center .button-group {
		flex-direction: row-reverse;
		justify-content: center;

		.additional-info {
			margin-left: 1rem;
		}
	}

	.cs-simple-button .button-group:last-child{
		padding-right:0
	}

	.button-group{
		margin-bottom: 1rem;
		
	    .button {
	        display: flex;
	        align-items: center;
	        justify-content: center;

	        a,
			::v-deep .cs-button {

	            color: white;
	            padding: 10px 15px;
	            border-radius: 30rem;
	            text-decoration: none;
	            text-align:center;

	            background: rgb(0,169,131);
	            background: linear-gradient(70deg, rgba(0,169,131,1) 0%, rgba(43,215,132,1) 100%);

	            cursor:pointer;
	            
	            &:hover {
	                background: rgb(49,223,140);
	                background: linear-gradient(90deg, rgba(49,223,140,1) 0%, rgba(49,223,140,1) 100%);
	            }
	        }
	    }

		&:last-child {
			margin-bottom: 0px;
		}
		
	    &:nth-child(1) .button a,
		&:nth-child(1) .button ::v-deep .cs-button {
	        background: rgb(254,178,46);
	        background: linear-gradient(70deg, rgba(254,178,46,1) 0%, rgba(255,227,194,1) 170%);

	        &:hover {
	            background: rgb(255,202,123);
	            background: radial-gradient(circle, rgba(255,202,123,1) 0%, rgba(255,202,123,1) 100%);
	        }
	    }

	    
	}
}
</style>
